
import ActionConfirm from "./ActionConfirm";

export interface IMainStore {
  tabBarConfirm: ActionConfirm;
};

export class MainStore {
  // Used to confirm actions that will change the tab we are in
  tabBarConfirm = new ActionConfirm();
}

const mainStore = new MainStore();
export default mainStore;