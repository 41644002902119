import { ACCESS_LEVELS } from "types/identity/AccessLevel";

export enum PERMISSIONS {
  structure_view = "portal.structure.view",
  users_view = "portal.users.view",
  products_view = "portal.products.view",
  structure_edit = "portal.structure.edit",
  users_edit = "portal.users.edit",
  products_edit = "portal.products.edit",
  shifts_view = "portal.shifts.view",
  shifts_edit = "portal.shifts.edit",
  storageapi_view = "portal.storageapi.view",
  storageapi_edit = "portal.storageapi.admin",
}

/**
 * Throttle time for any logout button in milliseconds.
 * The user can only click the logout button once every 5 seconds.
 */
export const LOGOUT_BUTTON_THROTTLE_MS = 5_000;

/**
 * Throttle time for any delete asset actions in milliseconds.
 * It is used in delete buttons, structure and panels.
 */
export const DELETE_ASSET_BUTTON_THROTTLE_MS = 15_000;

/**
 * Global StructureTree component constants.
 */
export const STRUCTURE_TREE_ICON_WIDTH = 22;

/**
 * Resizable Structure sidebar constants.
 */
export const STRUCTURE_SIDEBAR_MIN_WIDTH = 250;
export const STRUCTURE_SIDEBAR_MAX_WIDTH = 400;
export const STRUCTURE_SIDEBAR_ASSET_TREE_MAX_WIDTH = 280;

/**
 * Locations asset tree constants.
 * (Structure tree in asset wizard)
 */
export const LOCATIONS_ASSET_TREE_MAX_WIDTH = 600;

export const STORAGE_API_ASSET_ID = "StorageApi";

export const DEFAULT_ACCESS_LEVEL_ID = ACCESS_LEVELS.DEFAULT;
