// UTILS
import { v4 as uuidv4 } from "uuid";

// REACT
import {
  useState,
  createContext,
  useRef,
  useEffect,
  Fragment,
  KeyboardEvent,
  useCallback,
} from "react";

// LIBS
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Button,
  Input,
  ItemMeatballMenuInterface,
  MeatballMenu,
  Row,
  TreeItem,
  TreeView,
  useTheme,
  useTreeTriggers,
} from "@abb/common-ux";
import classNames from "classnames";

// COMPONENTS
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import itemStyles from "./StructureTreeItem.module.scss";

import { useTranslation } from "react-i18next";

import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";
import capitalize from "utils/capitalize";
import { DraggableStruct, DropHeader } from "components/DragAndDropComponents";
import { IStructureTreeItem } from "./StructureTreeItem";
import {
  useAddLevelMutation,
  useChangeParentMutation,
  useRemoveLevelMutation,
  useRenameMutation,
} from "./structureHooks";
import { findItem, updateItem } from "./utils";
import { TreeViewHeader } from "components/TreeViewHeader/TreeViewHeader";
import { TreeViewActions } from "components/TreeViewActions/TreeViewActions";
import { useConfig } from "components/Config/ConfigProvider";
import { isFunction } from "formik";
import { STRUCTURE_TREE_ICON_WIDTH } from "config/constants";
import { Modal } from "components/Modal/Modal";
import { useModal } from "components/Modal/ModalContext";
import { usePanel } from "components/Panel/PanelContext";

export interface StructureTreeProps {
  onClickOnItem?: (item: IStructureTreeItem) => void;
  filter?: string;
  onlyView?: boolean;
  expandOnSelectedItem?: boolean;
  items: IStructureTreeItem[];
  selectedId?: string;
  onChange?: (items: IStructureTreeItem[]) => void;
  maxWidth?: number;
}
const tPrefix = "app:screen.structure.sidebar.structureTree.";

const StructureTreeContext = createContext<any>({});

export const StructureTreeWithDrag = (props: StructureTreeProps) => {
  const { hasEditStructurePermission } = useLoggedUserPermission();

  return (
    <DndProvider backend={HTML5Backend}>
      <StructureTreeView {...props} onlyView={!hasEditStructurePermission} />
    </DndProvider>
  );
};

export const StructureTreeView = ({
  onClickOnItem,
  filter,
  onlyView,
  items,
  selectedId,
  onChange,
  maxWidth,
}: StructureTreeProps) => {
  // utils
  const { setModal, closeModal } = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    theme: {
      preset: {
        colors: { statusDanger },
      },
    },
  } = useTheme();
  const {
    config: { structureTreeMaxLevels },
  } = useConfig();

  // API
  const [addLevelMutation] = useAddLevelMutation();
  const [renameMutation] = useRenameMutation();
  const {
    removeLevel
  } = useRemoveLevelMutation();
  const [changeParentMutation] = useChangeParentMutation();
  const { closePanel } = usePanel();

  // Tree States
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [expandedBeforeFiltering, setExpandedBeforeFiltering] = useState<
    Record<string, boolean> | undefined
  >();
  const [isDndEnabled, setIsDndEnable] = useState(false);
  const [meatballOptions, setMeatballOptions] = useState<
    ItemMeatballMenuInterface[]
  >([]);
  const [meatballPosition, setMeatballPosition] = useState<{
    x: number;
    y: number;
  }>();

  // Item States
  const [isEditingId, setIsEditingId] = useState<string | undefined>(undefined);
  const [isAddingId, setIsAddingId] = useState<string | undefined>(undefined);
  const [editValue, setEditValue] = useState<string>("");
  const [addValue, setAddValue] = useState<string>("");
  const [isMeatballVisibleId, setIsMeatballVisibleId] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<{
    item: IStructureTreeItem;
    isRoot: boolean;
  }>();
  const { triggerProps, triggerExpandAll } = useTreeTriggers();

  /**
   * Array of hovered items ids, used to show the meatball menu on hover.
   * It includes the item id and all its parents from the root to the immediate parent.
   */
  const [hoveredStackIds, setHoveredStackIds] = useState<string[]>([]);

  /**
   * Closes meatball menu when Escape key is pressed.
   */
  useKeyPress("Escape", () => {
    if (isMeatballVisibleId) {
      setIsMeatballVisibleId("");
    }
  });

  /**
   * Confirms/Cancel Node edition keyboard handler
   */
  const handleEditKeyDown = (item: IStructureTreeItem) => (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    if (
      e.key === "Enter" &&
      item.name !== editValue &&
      editValue.trim().length !== 0
    ) {
      confirmEdit(item);
    }
    if (e.key === "Escape") {
      cancelAddEdit(item);
    }
  };
  /**
   * Confirms/Cancel Node Addition keyboard handler
   */
  const handleAddKeyDown = (item: IStructureTreeItem) => (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    if (e.key === "Enter" && addValue.trim().length !== 0) {
      confirmAdd(item);
    }
    if (e.key === "Escape") {
      cancelAddEdit(item);
    }
  };

  /**
   * Expand all items to avoid hidden matches for the current filter and store TreeView expand/collapsed items state to restore it after user is done filtering.
   * NOTE: It does not takes into consideration those expanded/collapsed performed while the filter is set.
   */
  const handleOnFilter = (expanded: Record<string, boolean>) => {
    if (!filter) {
      if (expandedBeforeFiltering) {
        // Restore expand/collapsed previous state
        setExpanded({ ...expandedBeforeFiltering });
      }
      // Clean up so it works the next time the filter is set.
      setExpandedBeforeFiltering(undefined);
    } else {
      if (!expandedBeforeFiltering) {
        // Save expand/collapsed state.
        setExpandedBeforeFiltering(expanded);
      }
      // Expand all items to avoid hidden matches for the current filter.
      triggerExpandAll();
    }
  };

  /**
   * Toggle Drag & Drop
   */
  const toggleDnd = () => {
    setIsDndEnable(!isDndEnabled);
  };
  /**
   * Expands the given item in the tree.
   * @param item
   */
  const expandItem = (item: IStructureTreeItem) => {
    const toExpand = item?.parents
      .map((item) => item.id)
      .concat(item.id)
      .reduce<Record<string, boolean>>((toExpand, itemId) => {
        toExpand[itemId] = true;
        return toExpand;
      }, {});
    setExpanded((expanded) => ({ ...toExpand, ...expanded }));
  };

  /**
   * Collapses the given item in the tree
   * @param item
   */
  const collapseItem = (item: IStructureTreeItem) => {
    setExpanded({ ...expanded, [item.id]: false });
  };

  /**
   * Opens the meatball menu with the given position and sets the options and actions
   * based on the given item and weather if it is a root or not.
   * @param item
   * @param isRoot
   * @param position
   */
  const openMeatball = useCallback(
    (
      item: IStructureTreeItem,
      isRoot: boolean,
      position: { x: number; y: number }
    ) => {
      const onAddLevel = (item: IStructureTreeItem) => {
        setIsAddingId(item.id);
        expandItem(item);
      };
      
      const removeStructureHandler = async () => {
        closeModal();
        await removeLevel(item);
        // Select root in the tree view if removed element is the selected one or one of its parents.
        const selectedItem = findItem(items, selectedId!);
        const isRemovingParentOfSelectedElement = selectedItem
          ? selectedItem.parents.find(
              (parentItem) => parentItem.id === item.id
            )
          : false;

        if (
          item.id === selectedId ||
          isRemovingParentOfSelectedElement
        ) {
          onClickOnItem!(items[0]);
        }

        // If item is included in the hovered stack, remove it and its children from the stack.
        if (
          item.id &&
          hoveredStackIds.includes(item.id)
        ) {
          setHoveredStackIds((stack) => {
            const index = stack.indexOf(item.id);
            return stack.slice(0, index);
          });
        }
      };

      if (isRoot) {
        setMeatballOptions([
          {
            title: t(tPrefix + "addLevel"),
            icon: "folder",
            onItemClickCallback: () => onAddLevel(item),
          },
          {
            title: t("rename"),
            icon: "edit",
            onItemClickCallback: () => {
              setEditValue(item.name);
              setIsEditingId(item.id);
            },
          },
        ]);
      } else {
        setMeatballOptions([
          {
            title: t(tPrefix + "addLevel"),
            icon: "folder",
            onItemClickCallback: () => onAddLevel(item),
            isDisabled: item.level >= structureTreeMaxLevels,
          },
          {
            title: t("rename"),
            icon: "edit",
            onItemClickCallback: () => {
              setEditValue(item.name);
              setIsEditingId(item.id);
            },
          },
          {
            title: t("delete"),
            icon: "trash",
            iconColor: statusDanger,
            onItemClickCallback: () => {
              setModal(
                <Modal
                  isOpen
                  closeModal={closeModal}
                  title={t(`confirmDeleteElement`)}
                  body={
                    <p>
                      {capitalize(
                        t(`app:screen.structure.tree.messages.confirm`)
                      )}
                    </p>
                  }
                  footer={{
                    footer: {
                      leftButton: {
                        text: t("cancel"),
                        type: "discreet-blue",
                        size: "small",
                        onPress: closeModal,
                      },
                      rightButton: {
                        text: t("confirm"),
                        type: "primary-blue",
                        size: "small",
                        onPress: removeStructureHandler,
                      },
                    },
                  }}
                />
              );
            },
          },
        ]);
      }
      setIsMeatballVisibleId(item.id);
      setMeatballPosition(position);
      setCurrentItem({ item, isRoot });
    },
    [
      closeModal,
      hoveredStackIds,
      items,
      onClickOnItem,
      removeLevel,
      selectedId,
      setModal,
      statusDanger,
      structureTreeMaxLevels,
      t,
    ]
  );

  /**
   * Make request to edit level to the structure.
   */
  const confirmEdit = async (item: IStructureTreeItem) => {
    const properties = {
      name: editValue.trim(),
      type: item.type,
      factoryStructure: true,
    };

    await renameMutation({
      variables: {
        id: item.id,
        properties,
      },
    });

    setIsEditingId(undefined);

    // If there is an onChange callback, call it with all the updated structure
    if (isFunction(onChange)) {
      const newStructure = updateItem(items, item.id, properties);
      onChange(newStructure);
    }
  };

  /**
   * Make request to add new level to the structure.
   */
  const confirmAdd = async (item: IStructureTreeItem) => {
    await addLevelMutation({
      variables: {
        id: uuidv4(),
        parentId: item.id,
        properties: {
          name: addValue.trim(),
          type: "subgroup",
          factoryStructure: true,
        },
      },
    });
    setIsAddingId(undefined);
    setAddValue("");
  };

  /**
   * Inline add/edition check-mark icon handler
   */
  const confirmAddEdit = async (item: IStructureTreeItem) => {
    if (isAddingId) {
      confirmAdd(item);
    }
    if (isEditingId) {
      confirmEdit(item);
    }
  };
  /**
   * Inline cancel add/edition icon handler
   */
  const cancelAddEdit = async (item: IStructureTreeItem) => {
    // collapseItem(item);
    setEditValue("");
    setIsEditingId(undefined);
    setAddValue("");
    setIsAddingId(undefined);
  };

  /**
   * Handles drag and drop structure movements.
   * @param from old parent structure item.
   * @param to new parent structure item.
   */
  const moveStructure = (from: IStructureTreeItem, to: IStructureTreeItem) => {
    changeParentMutation({ variables: { id: from.id, parentId: to.id } });
  };

  // Effects
  /**
   * Expand the item given by the selectedId property if any.
   */
  useEffect(() => {
    if (selectedId) {
      expandItem(findItem(items, selectedId));
    }
  }, [items, selectedId]);

  /**
   * Recursively render items
   */
  const renderItems = (items: IStructureTreeItem[], level = 0) =>
    items.map((item) => {
      const isEditing = item.id === isEditingId;
      const isAdding = item.id === isAddingId;
      const isRef = item.id === currentItem?.item.id;
      const isHovered = item.id === hoveredStackIds[hoveredStackIds.length - 1];
      const isMeatballVisible = item.id === isMeatballVisibleId;
      const isRoot = level === 0;

      /**
       * Render item default actions
       */
      const renderActions = (): JSX.Element =>
        !onlyView ? (
          <TreeViewActions
            actionWrapperClassName={classNames({
              [itemStyles.meatballVisible]: isHovered || isMeatballVisible,
            })}
            ref={isRef ? ref : undefined}
            showEdit={isRoot}
            onMenuClick={(e: any) => {
              openMeatball(item, isRoot, {
                x: e.clientX,
                y: e.clientY,
              });
              e.stopPropagation();
            }}
            onEditClick={toggleDnd}
            key={`actions-${item.id}`}
          />
        ) : (
          <></>
        );

      /**
       * Render item actions on add/edit mode.
       */
      const renderEditActions = (): JSX.Element =>
        !onlyView ? (
          <Row className={itemStyles.actions} key={`actiions-${item.id}`}>
            <>
              <Button
                size="small"
                type="discreet-black"
                icon="check-mark"
                onPress={() => confirmAddEdit(item)}
                disabled={
                  isEditing
                    ? editValue.length === 0 ||
                      editValue.trim().length === 0 ||
                      editValue === item.name
                    : addValue.length === 0 || addValue.trim().length === 0
                }
              />
              <Button
                size="small"
                type="discreet-black"
                icon="error-circle-1"
                onPress={() => cancelAddEdit(item)}
              />
            </>
          </Row>
        ) : (
          <></>
        );
      /**
       * Renders the content of an tree item replacing the title in the "title area"
       * Used to render rename input, when is editing a node.
       */
      const renderContent = () => {
        if (isEditing) {
          return (
            <div
              key={`edit-${item.id}`}
              data-cy="structureTreeView-item-content"
              className={itemStyles.addEditInput}
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                autoFocus
                label=""
                value={editValue}
                onChangeText={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  setEditValue(e.target?.value);
                }}
                onKeyDown={handleEditKeyDown(item)}
              />
            </div>
          );
        }
        if (isRoot) {
          return (
            <TreeViewHeader
              key={`header-${item.id}`}
              text={item.name}
              icon="factory"
              maxWidth={maxWidth}
            />
          );
        }
      };

      /* TEMPLATES */
      if (isDndEnabled) {
        if (isRoot) {
          /* Drag and drop for the root */
          return (
            <Fragment key={item.id}>
              <DropHeader
                handleEditClick={toggleDnd}
                item={item}
                onDrop={moveStructure}
                maxWidth={maxWidth}
              />
              <div className={itemStyles.draggableItemContainer}>
                {renderItems(item.children, level + 1)}
              </div>
            </Fragment>
          );
        }

        /* Drag and drop for inner items */
        return (
          <DraggableStruct
            title={item.name}
            data={item}
            key={`item-${item.name}-${item.id}`}
            onDrop={moveStructure}
          >
            {renderItems(item.children, level + 1)}
          </DraggableStruct>
        );
      }

      /* Regular mode no Drag and Drop */
      return (
        <div
          key={item.id}
          className={itemStyles.itemContainer}
          onMouseEnter={(e) => {
            // On hover, update the hover stack with the item id and its parents
            if (!hoveredStackIds.includes(item.id)) {
              const parentIds = item.parents.map((parent) => parent.id);
              setHoveredStackIds([...parentIds, item.id]);
            }
          }}
          onMouseLeave={(e) => {
            // On leave, remove the item id and its children from the stack
            if (hoveredStackIds.includes(item.id)) {
              setHoveredStackIds((stack) => {
                const index = stack.indexOf(item.id);
                return stack.slice(0, index);
              });
            }
          }}
        >
          <TreeItem
            title={isRoot || isEditing ? "" : item.name}
            id={item.id}
            margin={STRUCTURE_TREE_ICON_WIDTH}
            icon={isRoot ? "" : "dynamic-folder"}
            actions={
              isEditing || item.isNew ? renderEditActions : renderActions
            }
            content={renderContent()}
            style={
              maxWidth
                ? {
                    title: {
                      // Adjust the title width based on the level of the item
                      // The max width is the sidebar max width minus the icon width per level
                      maxWidth: maxWidth - STRUCTURE_TREE_ICON_WIDTH * level,
                    },
                  }
                : {}
            }
          >
            {/* Can't pass an array and an conditional item and an array so you need to include the conditional element inside the children's array, so is always an array.*/}
            {[
              ...(isAdding
                ? [
                    <TreeItem
                      key="new"
                      title=""
                      id="new"
                      actions={renderEditActions}
                      icon="dynamic-folder"
                      style={{} as any}
                      children={[]}
                      content={
                        <div
                          data-cy="structureTree-item-content"
                          className={itemStyles.addEditInput}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Input
                            label=""
                            autoFocus
                            value={addValue}
                            onChangeText={(e) => {
                              setAddValue(e.target.value);
                            }}
                            onKeyDown={handleAddKeyDown(item)}
                          />
                        </div>
                      }
                    />,
                  ]
                : []),
              ...renderItems(item.children, level + 1),
            ]}
          </TreeItem>
        </div>
      );
    });

  return (
    <StructureTreeContext.Provider
      value={{ expandItem, isDndEnabled, toggleDnd, collapseItem }}
    >
      <TreeView
        expanded={expanded}
        onItemClick={(clickedItemId: string) => {
          closePanel();
          if(clickedItemId!=="new"){
            onClickOnItem!(findItem(items, clickedItemId));
          }
        }}
        filter={filter}
        hideFiltered
        selectedId={selectedId}
        onFilter={handleOnFilter}
        {...triggerProps}
      >
        {renderItems(items)}
      </TreeView>
      {/* Contextual/meatball menu template */}
      {isMeatballVisibleId && (
        <div className={itemStyles.fixMeatballMenu}>
          <MeatballMenu
            scrollAfter={0}
            maxOptionLength={0}
            openingPosition="right-down"
            setVisible={(isVisible: boolean) =>
              setIsMeatballVisibleId(isVisible ? selectedId || "" : "")
            }
            menuItems={meatballOptions}
            xPos={meatballPosition?.x ?? 0}
            yPos={meatballPosition?.y ?? 0}
          ></MeatballMenu>
        </div>
      )}
    </StructureTreeContext.Provider>
  );
};
