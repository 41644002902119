import { useTranslation } from "react-i18next";

import capitalize from "utils/capitalize";

import styles from "../Wizard.module.scss";

import { StepProps } from "../DeviceWizard";
import { TFunction } from "i18next";
import { PasswordText } from "components/PasswordText/PasswordText";
import { WizardFormModel } from "../WizardFormModel";
import { RobotControllerType } from "views/Structure/types/RobotControllerType";
import { AssetType } from "../../types/AssetType";
import { SummaryItem } from "components/Wizard/SummaryItem";
import { ReactNode } from "react";

/**
 * This array contains the properties that will be always omitted from the Confirm step.
 * Specific type props not related to the current asset type are omitted as well using
 * the specificTypeProps dictionary below.
 */
const basePropsToOmit: (keyof WizardFormModel)[] = [
  // "certificateFilename", --> Deprecated due to US #13076
  // "privateKeyFilename", --> Deprecated due to US #13076
  "sourceTopics",
  "destinationTopics",
];

/**
 * This dictionary contains specific fields that are only related to a specific asset type.
 * This is used to remove residual fields that are not related to the selected asset type.
 *
 * When a selector field has a default value and it is rendered but then the user changes
 * the asset type, the field won't be removed otherwise.
 *
 * e.g. Selecting OPCUA type will render the opcuaSecurityPolicy and opcuaMessageSecurityMode
 * fields. If the user then changes the asset type to another type, these fields won't be
 * removed otherwise and will appear in the Confirm step as "None".
 */
const specificTypeProps: Record<AssetType, (keyof WizardFormModel)[]> = {
  [AssetType.Robot]: ["robotControllerType"],
  [AssetType.PLCOpcUA]: ["opcuaMessageSecurityMode", "opcuaSecurityPolicy"],
  [AssetType.MQTT]: [],
  [AssetType.Database]: [],
  [AssetType.IPS]: [],
};

const fieldsToTranslate = [
  "pathname",
  "hostname",
  "opcuaSecurityPolicy",
  "opcuaMessageSecurityMode",
  "sourceTopics",
  "destinationTopics",
];
const ConfirmStep = ({ formik }: StepProps) => {
  const { t } = useTranslation();

  // Check if the form has the topics properties set as they are displayed separately
  const { sourceTopics, destinationTopics } = formik.values;
  const hasTopics = Boolean(sourceTopics?.length || destinationTopics?.length);

  // Calculate which properties are not specific to the current asset type and should be omitted
  const propsToOmit = [...basePropsToOmit];

  for (const [type, typePops] of Object.entries(specificTypeProps)) {
    if (formik.values.type !== type) {
      propsToOmit.push(...typePops);
    }
  }

  // Generate the summary items
  const summaryItems = Object.entries(formik.values).reduce(
    (memo, [key, value]) => {
      // If it is a prop to omit, don't render it
      if (propsToOmit.includes(key as keyof WizardFormModel)) {
        return memo;
      }

      // If it has no value or it is an empty array, don't render it
      if (!value || (Array.isArray(value) && value.length === 0)) {
        return memo;
      }

      memo.push(
        <SummaryItem
          key={key}
          value={getFormModelValueAsString(key, value, t)}
          label={getFormModelTitle(key, t)}
        />
      );

      return memo;
    },
    [] as ReactNode[]
  );

  return (
    <div className={styles.summaryContainer}>
      <h1>{capitalize(t("summary"))}</h1>
      <div className={styles.summaryList}>{summaryItems}</div>

      {hasTopics && (
        <div>
          {!!sourceTopics?.length && (
            <SummaryItem
              value={getFormModelValueAsString(
                "sourceTopics",
                formik.values.sourceTopics,
                t
              )}
              label={getFormModelTitle("sourceTopics", t)}
            />
          )}
          {!!destinationTopics?.length && (
            <SummaryItem
              value={getFormModelValueAsString(
                "destinationTopics",
                formik.values.destinationTopics,
                t
              )}
              label={getFormModelTitle("destinationTopics", t)}
            />
          )}
        </div>
      )}
    </div>
  );
};

const getFormModelTitle = (key: string, t: TFunction): string => {
  if (fieldsToTranslate.includes(key)) {
    return capitalize(t(`app:screen.structure.wizard.form.fields.${key}`));
  }
  return t(key);
};

const getFormModelValueAsString = (
  key: string,
  value: any,
  t: TFunction
): string | string[] | JSX.Element => {
  switch (key) {
    case "location":
      return value.label;
    case "tag":
      return value.map((item: { label: string }) => item.label).join(", ");
    case "default":
      return value ? t("yes") : t("no");
    case "password":
      return <PasswordText password={value}></PasswordText>;
    case "type":
    case "databaseType":
      return t(value);
    case "robotControllerType":
      return value === RobotControllerType.RobotController
        ? capitalize(
            t("app:screen.structure.wizard.form.fields.robotController")
          )
        : capitalize(
            t("app:screen.structure.wizard.form.fields.virtualController")
          );

    case "opcuaSecurityPolicy":
      return t(
        `app:screen.structure.wizard.form.fields.opcuaSecurityPolicyOptions.${value}`
      );
    case "opcuaMessageSecurityMode":
      return t(
        `app:screen.structure.wizard.form.fields.opcuaMessageSecurityModeOptions.${value}`
      );
    // field hidden due the US: 13076
    // case "certificate":
    //   return values.certificateFilename
    // case "privateKey":
    //   return values.privateKeyFilename
    default:
      return value;
  }
};

export default ConfirmStep;
