// REACT
import { useContext, useState, useEffect, useRef, useCallback } from "react";

// REACT ROUTER
import { useParams } from "react-router-dom";

// GRAPHQL
import { getGroup } from "gql/identity/queries/groupQueries";
import { getAllUsers } from "gql/identity/queries/userQueries";
import { useQuery } from "@apollo/client";

// LIBS
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";

// SERVICES
import { ApolloContexts } from "services/ApolloService";
import { UsersGridContext } from "layouts/main/MainLayout";

// I18NEXT
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Tags } from "components/Tags/Tags";
import { QueryResultRenderer } from "components/QueryResultRenderer/QueryResultRenderer";
import { UsersDataGrid } from "../UsersDataGrid";
import { UserFilter, UserFilters } from "../panels/Filters";
import { User as PanelUser } from "../panels/User";
import DataGridSkeleton from "components/Skeletons/commonux/DataGrid";
import { useAuth } from "@abb/identity-auth-react";
import { Button, DatagridRow, ThreeStateValue } from "@abb/abb-common-ux-react";
import { useConfig } from "components/Config/ConfigProvider";

// INTERFACES
import { Identity } from "types/identity/Identity";
import { User } from "types/identity/User";
import { Group } from "types/identity/Group";
import { Role } from "types/identity/Role";

// STYLES
import styles from "../Users.module.scss";
import {
  Filters,
  Row,
  Text,
  useTheme,
  useViewLayoutContentStyles,
} from "@abb/common-ux";
import { getFilterStatus } from "../Users";
import { usePanel } from "components/Panel/PanelContext";
import Skeleton from "components/Skeletons/Skeleton";

interface UserActionsProps {
  id: string;
}

//Aux component
const UserActions = ({ id }: UserActionsProps) => {
  const { setPanel } = usePanel();
  const { selectedUsers } = useContext(UsersGridContext);

  const users =
    selectedUsers.length > 0 && selectedUsers.includes(id)
      ? selectedUsers
      : [id];
  return (
    <Button
      dataAttributes={{ cy: "edit" }}
      icon="abb/edit"
      type="discreet-black"
      onClick={() => setPanel(<PanelUser users={users} />)}
    />
  );
};
const userGroups = (user: User) =>
  user.identity.assignedGroups
    .filter((g) => g.isInternal === false)
    .map((group: Group) => ({
      name: group.label,
    }));

const userRoles = (user: User) => {
  return user.identity.assignedGroups
    .flatMap((group: Group) =>
      group.roles?.map((role: Role) => ({
        name: role?.label || role.name,
      }))
    )
    .filter((t): t is { name: string } => !!t)
    .filter(
      (r, index, array) => array.findIndex((or) => or.name === r.name) === index
    );
};

/*
 * UsersSection
 * Main users view when not looking at group summary
 */
const UsersSection = ({
  filters,
  search,
  setSearch,
  setFilters,
}: {
  filters?: UserFilter;
  search?: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setFilters: React.Dispatch<React.SetStateAction<UserFilter | undefined>>;
}) => {
  // STATES
  const [selectAllState, setSelectAllState] = useState(
    ThreeStateValue.Unchecked
  );
  const [loadingGrandChildren, setLoadingGrandChildren] = useState(true);
  const [users, setUsers] = useState<any[]>([]);
  const { theme } = useTheme();
  const { title: titleStyle } = useViewLayoutContentStyles(theme.preset, {});
  // NAVIGATION
  let params = useParams<{ section?: string; idElement: string }>();

  // CONTEXT
  const { setPanel } = usePanel();
  const { selectedUsers, setSelectedUsers } = useContext(UsersGridContext);

  // CUSTOM HOOKS
  const { hasEditUserPermission } = useLoggedUserPermission();
  const { config } = useConfig();
  const { user: userAuth } = useAuth();
  const { t } = useTranslation();

  // REF
  const setSelectedUsersRef = useRef(setSelectedUsers);

  const { context } = ApolloContexts.Identity;

  const usersQuery = useQuery<{ usersByNameAndEmail: User[] }>(getAllUsers, {
    fetchPolicy: "network-only",
    context,
  });
  const { data: usersData } = usersQuery;

  const groupQuery = useQuery(getGroup, {
    fetchPolicy: "network-only",
    variables: { id: params.idElement },
    skip:
      !params.idElement || !!(params.section && params.section !== "groups"),
    context,
  });
  const { data: groupData } = groupQuery;

  const isSuperUser = useCallback(
    (user: User) => {
      return user.identity.assignedGroups.some(
        (g) => g.name === config.admin.groupName
      );
    },
    [config]
  );
  // EFFECTS
  useEffect(() => {
    const totalSelectableUsers = usersData?.usersByNameAndEmail
      .filter((u: User) =>
        users.some((userGrid) => userGrid.fields.id === u.id)
      )
      .filter((u: User) => !isSuperUser(u)).length;
    setSelectAllState(
      users.length && selectedUsers.length === totalSelectableUsers
        ? ThreeStateValue.Checked
        : ThreeStateValue.Unchecked
    );
  }, [
    selectedUsers,
    setSelectAllState,
    users,
    usersData?.usersByNameAndEmail,
    isSuperUser,
  ]);

  useEffect(() => {
    return () => {
      // This rule does not apply as it does not point to a DOM element
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setSelectedUsersRef.current([]);
    };
  }, []);

  // UTILS

  const getUserInfo = (userGridElement: any): User | undefined => {
    return usersData?.usersByNameAndEmail.find(
      (u: User) => u.id === userGridElement.fields.id
    );
  };

  const canEditSuperAdmin = useCallback(
    (user: User) => {
      return user.email === userAuth?.profile.email && isSuperUser(user);
    },
    [isSuperUser, userAuth]
  );

  // ACTIONS
  const handleOnUserSelected = (rowIndex: string) => {
    const newSelectedUsers = users.map((user: any, index: any) => {
      const filteredUser = getUserInfo(user);

      // Check if the user selected has the super group to avoid selecting it. US #22971
      const newDatum = Object.assign(user, {
        isSelected:
          filteredUser && isSuperUser(filteredUser)
            ? false
            : rowIndex === index
            ? !user.isSelected
            : user.isSelected,
      });

      return newDatum;
    });

    setUsers(newSelectedUsers);

    setSelectedUsers(
      newSelectedUsers
        .filter(({ isSelected }: any) => isSelected === true)
        .map(({ fields: { id } }: any) => id)
    );
  };

  const handleSelectAll = () => {
    const usersSelected = users.map((u: any) => {
      const filteredUser = getUserInfo(u);
      return {
        ...u,
        isSelected:
          filteredUser &&
          !isSuperUser(filteredUser) &&
          selectAllState === ThreeStateValue.Unchecked,
      };
    });
    setUsers(usersSelected);

    setSelectedUsers(
      usersSelected
        .filter(({ isSelected }: any) => isSelected === true)
        .map(({ fields: { id } }: any) => id)
    );
  };

  const userData = t("users");

  // USERS DATA
  useEffect(() => {
    const setDataGrid = async () => {
      const { groups: filterGroups, email: filterEmail, phone: filterPhone } =
        filters || {};

      const dataSource = !params.section
        ? usersData?.usersByNameAndEmail || []
        : params.section === "groups"
        ? groupData?.group.identities.map((identity: Identity) =>
            usersData?.usersByNameAndEmail.find(
              (user: User) => user.identity.id === identity.id
            )
          ) || []
        : [];

      const groupedUsers =
        dataSource
          // Hotfix: Remove this undefined filter after the deleteUser mutation bug
          // is fixed, and leave the identity.status "active" filter only.
          .filter((user: User) => typeof user !== "undefined")
          .filter(
            (user: User) =>
              (!search ||
                `${user.firstName?.toLowerCase() || ""} ${
                  user.lastName?.toLowerCase() || ""
                }`.includes(search?.toLowerCase() || "")) &&
              (!filterEmail ||
                user.email
                  ?.toLowerCase()
                  .includes(filterEmail ? filterEmail.toLowerCase() : "")) &&
              (!filterPhone ||
                user.phone
                  ?.toLowerCase()
                  .includes(filterPhone ? filterPhone.toLowerCase() : "")) &&
              (filterGroups === undefined ||
                filterGroups.length === 0 ||
                user.identity.assignedGroups.filter(
                  (group: Group) =>
                    filterGroups?.findIndex(
                      (filterGroup: string) => filterGroup === group.id
                    ) !== -1
                ).length > 0)
          ) || [];

      setUsers(
        groupedUsers.map(
          (user: User): DatagridRow => {
            const groups = userGroups(user);
            const roles = userRoles(user);
            const canEdit =
              hasEditUserPermission &&
              (!isSuperUser(user) ||
                (isSuperUser(user) && canEditSuperAdmin(user)));

            return {
              fields: {
                id: user.id,
                name:
                  user.firstName || user.lastName
                    ? [user.firstName, user.lastName].join(" ")
                    : "-",
                groups: groups.length ? (
                  <Tags maxVisibleTags={4} tags={groups} />
                ) : (
                  "-"
                ),
                email: user.email,
                phone: user.phone || "-",
                roles: roles.length ? (
                  <Tags maxVisibleTags={4} tags={roles} />
                ) : (
                  "-"
                ),
                accessLevel: t(
                  `common:accessLevelLabels.${user.identity.accessLevel.name}`
                ),
                actions: canEdit ? (
                  <UserActions id={user.id}></UserActions>
                ) : null,
              },
              isSelected: false,
            };
          }
        )
      );
      setLoadingGrandChildren(false);
    };
    if (usersData) {
      setDataGrid();
    }
  }, [
    search,
    filters,
    usersData,
    groupData,
    params.section,
    hasEditUserPermission,
    isSuperUser,
    canEditSuperAdmin,
    t,
  ]);

  const queryResults = [
    {
      queryResult: usersQuery,
      dataKey: "usersByNameAndEmail",
    },
    ...(params.section === "groups"
      ? [
          {
            queryResult: groupQuery,
            dataKey: "group",
          },
        ]
      : []),
  ];
  return (
    <Filters
      filtersInUse={getFilterStatus(filters)}
      openPanel={() =>
        setPanel(<UserFilters filters={filters} setFilter={setFilters} />)
      }
      title={
        <Row>
          <Text style={titleStyle}>{userData}</Text>
        </Row>
      }
      onSearch={setSearch}
      placeholder={t("search")}
    >
      <div className={styles.usersSection}>
        <QueryResultRenderer
          queryResults={queryResults}
          skeleton={<DataGridSkeleton id={"users_datagrid_skeleton"} />}
        >
          {() => {
            return loadingGrandChildren ? (
              <Skeleton>
                <DataGridSkeleton id={"users_datagrid_skeleton"} />
              </Skeleton>
            ) : (
              <UsersDataGrid
                data={users}
                handleOnUserSelected={handleOnUserSelected}
                handleSelectAll={handleSelectAll}
                selectAllState={
                  users && users.length
                    ? selectAllState
                    : ThreeStateValue.Unchecked
                }
                filters={filters}
              />
            );
          }}
        </QueryResultRenderer>
      </div>
    </Filters>
  );
};

export default UsersSection;
