import { useEffect } from "react";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";

import TextInput from "components/FormikCommonUX/Text";

import styles from "../Wizard.module.scss";
import { StepProps, deviceInitialValues } from "../DeviceWizard";
import { AssetType } from "views/Structure/types/AssetType";
import { WizardFormModel } from "../WizardFormModel";
import { ProtocolType } from "views/Structure/types/ProtocolType";
import { useConfig } from "components/Config/ConfigProvider";


const fieldsToKeepAfterTypeChange = ["type", "id", "name", "location", "tag"];

const useDefaultValues = (apply: (field: string, value: string) => void) => {
  const applyDefaultValues = (type: string) => {
    const typeDependantDefaultValues: {
      [type: string]: {
        [field: string]: string;
      };
    } = {
      robot: {
        user: "Default User",
        password: "robotics",
      },
    };
    const defaultValues = typeDependantDefaultValues[type];
    if (defaultValues) {
      Object.entries(defaultValues)?.forEach(([field, value]) => {
        apply(field, value);
      });
    }
  };
  return { applyDefaultValues };
};

const AddStep = ({ formik, isEdit }: StepProps) => {
  const { config } = useConfig();
  const { t } = useTranslation();
  const { applyDefaultValues } = useDefaultValues(formik.setFieldValue);
    const typeOptions = [
    { label: t("robot"), value: AssetType.Robot },
    { label: t("plcOpcua"), value: AssetType.PLCOpcUA },
    { label: t("mqtt"), value: AssetType.MQTT },
    { label: t("database"), value: AssetType.Database },
    ...(config.featureFlags.ips.enabled ? [{ label: t("ips"), value: AssetType.IPS }] : [])
  ];

  useEffect(() => {
    if (!formik.values.user && !isEdit) {
      applyDefaultValues(formik.values.type);
    }
    // This effect only needs to run once as the handleTypeChange will be the responsible to maintain the correct state.
    // For this reason we can ignore the dependency array.
    // Doing it with a correct dependency array would probably need a bigger refactor of the wizard.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = (newType: AssetType) => {
    formik.setFieldValue("type", newType);

    Object.keys(formik.values)
      .filter((field) => fieldsToKeepAfterTypeChange.indexOf(field) === -1)
      .forEach((field) =>
        formik.setFieldValue(
          field,
          deviceInitialValues[field as keyof WizardFormModel]
        )
      );
    if (newType === AssetType.PLCOpcUA) {
      formik.setFieldValue("protocol", ProtocolType.OPC_TCP);
    }
    if (!isEdit) {
      applyDefaultValues(newType);
    }
  };

  return (
    <div className={styles.stepContainer}>
      <div style={{ display: "flex", alignContent: "stretch", gap: 16 }}>
        <div style={{ flex: 2 }}>
          <TextInput
            name="name"
            placeholder={t("assetName")}
            formik={formik}
            required
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextInput
            disabled={!!isEdit}
            name="id"
            label={t("id")}
            placeholder={t("app:screen.structure.wizard.add.deviceIdExample")}
            formik={formik}
            required
          />
        </div>
      </div>

      <Dropdown
        onChange={(option) => handleTypeChange(option[0].value)}
        sizeClass="large"
        label={t("type")}
        placeholder={t("deviceTypeSelect")}
        value={
          [{ value: formik.values?.type, label: t(formik.values?.type) }] || []
        }
        required
      >
        {typeOptions.map((option) => (
          <DropdownOption
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </Dropdown>
    </div>
  );
};

export default AddStep;
