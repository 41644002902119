import { AccessLevel } from "./AccessLevel";
import { Group } from "./Group";
import { Role } from "./Role";

export enum IdentityTypeEnum {
  LOCAL = "local",
  EXTERNAL = "external",
  SERVICE = "service",
}

export enum IdentityStatusEnum {
  INITIALIZED = "initialized",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
  CLEANED = "cleaned",
}

export interface Identity {
  id: string;
  status: `${IdentityStatusEnum}`;
  assignedGroups: Group[];
  ownedRoles: Role[];
  type: `${IdentityTypeEnum}`;
  accessLevel: AccessLevel;
}
