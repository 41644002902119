//HOOKS
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import {
  NotifyError,
  useGlobalNotification,
} from "services/GlobalNotification/GlobalNotificationService";

//LIBS
import yup from "libs/validation/yup";
import { parse } from "papaparse";
import xss from "xss";
import cx from "classnames";
import i18n from "libs/i18n";
import useKeyPress from "libs/@abbrda/abb-common-ux-react/internalUtils/useKeyPress";

//COMPONENTS
import {
  Dropdown,
  DropdownOption,
  LoadingIndicator,
} from "@abb/abb-common-ux-react";
import { ErrorBox } from "components/ErrorBox/ErrorBox";
import InfoButton from "components/InfoButton";
import { Button, Icon, SlidePanel } from "@abb/common-ux";
import { usePanel } from "components/Panel/PanelContext";
//MUTATIONS
import { addStructure } from "gql/storageApi/mutations/structureMutations";
import { upsertAssetsWithDomains } from "gql/storageApi/mutations/assetMutations";

// ASSETS
import sample_all_structure from "./assets/sample_file_all_structure.csv";
import sample_all_assets from "./assets/sample_file_all.csv";

//CONSTANTS
import { fullAssetPropertyList } from "./hooks/useQueryAssetsData";
import {
  getDomains,
  getDomainsListId,
} from "gql/storageApi/queries/domainQueries";
import { insertDomains } from "gql/storageApi/mutations/assetDomainsMutations";

// STYLES
import styles from "./Structure.module.scss";
import classNames from "classnames";
import { ABBColors } from "@abb/common-ux-colors";
import { getAllAssets } from "gql/storageApi/queries/assetQueries";

// TYPES
import { QueryAsset } from "types/storageApi/QueryAsset";
import { ApolloContexts } from "services/ApolloService";
import { pick } from "lodash";
import { useStructureContext } from "views/Structure/StructureContext";
import { getStructureChildrenIds } from "utils/assetStucture";

interface ImportStructureToCSVProps {
  structureId?: string;
  onlyAllowImportAssets?: boolean;
}

interface StructureForm {
  type: string;
}

export interface Structure {
  structureid: string;
  parentid?: string;
  name: string;
  type: string;
}

export enum StructureImportType {
  All = "all",
  AssetsOnly = "assets",
}

export const structurePropertyList = [
  "structureid",
  "parentid",
  "name",
  "type",
];

export interface Asset {
  modelid?: string;
  assetname: string;
  assetid: string;
  structureid?: string;
  tags: string;
}

export enum AssetModel {
  All = "all.models",
  RobotRC = "asset.robot.rc.dfa.raro",
  RobotRV = "asset.robot.vc.dfa.raro",
  OpcUA = "asset.opcua.dfa.raro",
  MQTT = "asset.mqtt.dfa.raro",
  MongoDB = "asset.mongodb.dfa.raro",
  PostgreSQL = "asset.timescale.dfa.raro",
  IPS = "asset.ips.dfa.raro",
}

export const ImportStructureFromCSV = (props: ImportStructureToCSVProps) => {
  // states
  const [loading, setLoading] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);

  // hooks
  const { t } = useTranslation();
  const { closePanel, isOpen } = usePanel();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    publishSuccess,
    publishInfo,
    publishAlert,
    publishWarning,
  } = useGlobalNotification();

  const {
    setFieldValue,
    setFieldTouched,
    setFieldError,
    errors: formikErrors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      type: StructureImportType.AssetsOnly,
      file: undefined,
    },
    validationSchema: yup.object().shape({
      type: yup.string(),
      file: yup.object(),
    }),
    onSubmit: () => {},
  });

  const {
    selectedStructure,
    setSelectedStructureChildrenIds,
  } = useStructureContext();

  // queries
  const { context } = ApolloContexts.Hasura;

  const { data: allAssets } = useQuery(getAllAssets, {
    fetchPolicy: "network-only",
    context,
  });
  const allAssetList: QueryAsset[] = allAssets?.master_asset;
  const [insertStructureMutation] = useMutation(addStructure, {
    refetchQueries: ["getStructures"],
    context,
  });
  const [upsertAssetsMutation] = useMutation(upsertAssetsWithDomains, {
    refetchQueries: ["assetsDatagrid", "getAllAssets"],
    context,
  });
  const [createNewDomains] = useMutation(insertDomains, {
    context,
  });
  const { data: domainsData } = useQuery(getDomains, {
    context,
  });
  const domainsList = domainsData?.master_code_list_item;
  const { data: domainListIdData } = useQuery(getDomainsListId, {
    fetchPolicy: "network-only",
    context,
  });

  const codeListId = domainListIdData?.master_code_list[0]?.id;

  const handleInputChange = useCallback(() => {
    setFileLoaded(
      inputRef?.current?.files?.length === 1 &&
        inputRef.current.files[0].type === "text/csv"
    );
  }, []);

  const handleImport = useCallback(() => {
    const input = inputRef.current;

    // Only one file can be imported at a time
    if (input?.files?.length !== 1) {
      return;
    }

    setLoading(true);

    parse<Structure | Asset>(input.files[0], {
      header: true,
      skipEmptyLines: "greedy",
      transform: (value) => xss(value).trim(),
      complete(result) {
        // If there are errors, show the first one and end the process
        if (result.errors.length !== 0) {
          setLoading(false);
          setFieldError(
            "file",
            `${t("app:screen.structure.panel.importStructureCSV.fileError")}${
              result.errors[0].message
            }`
          );
          return;
        }

        const importElements = result.data;

        // Validate the CSV file
        getValidationSchema(values.type)
          .validate(importElements)
          .then(function () {
            publishInfo(
              t("app:screen.structure.panel.importStructureCSV.importing")
            );

            /**
             * ------ Asset-only import start ------
             */
            if (values.type === StructureImportType.AssetsOnly) {
              // Get the assets in other folders than the ones in CSV to warn the user
              const assetsInAnotherFolder = (importElements as Asset[]).reduce(
                (result: Asset[], asset: Asset) => {
                  const existingAsset = allAssetList.find(
                    (item) => item.assetid === asset.assetid
                  );

                  if (
                    existingAsset &&
                    existingAsset.master_assetstructure_array[0].structureid !==
                      props.structureId
                  ) {
                    result.push(asset);
                  }
                  return result;
                },
                []
              );

              // Get the new domains that are not in the database
              const newDomains = (importElements as Asset[]).flatMap((asset) =>
                asset.tags
                  .split(",")
                  .filter(
                    (domainName) =>
                      !domainsList.find(
                        (domain: any) => domain.value.name === domainName
                      ) && domainName !== ""
                  )
              );

              // Create the new domains in the database.
              createNewDomains({
                variables: {
                  objects: newDomains.map((domain) => ({
                    value: { name: domain },
                    code_list_id: codeListId,
                  })),
                },
              })
                .then((newDomainsResponse) => {
                  // Add the new domains to the existing local domains list
                  const newDomainsList = [
                    ...domainsList,
                    ...newDomainsResponse.data["insert_master_code_list_item"]
                      .returning,
                  ];
                  const {
                    objects,
                    assetIds,
                    assetDomains,
                  } = (importElements as Asset[]).reduce(
                    (
                      insertion: {
                        objects: any;
                        assetIds: string[];
                        assetDomains: any;
                      },
                      asset: Asset
                    ) => {
                      insertion.assetIds.push(asset.assetid);
                      let {
                        assetname,
                        assetid,
                        modelid,
                        tags,
                        ...properties
                      } = asset;
                      let parsedProperties: any = properties;
                      parsedProperties = getModelProperties(
                        modelid as AssetModel,
                        properties
                      );

                      insertion.objects.push({
                        ...{
                          assetid,
                          assetname,
                          modelid: modelid || values.type,
                          master_assetconfiguration_array: {
                            data: {
                              activeat: new Date().toISOString(),
                              propertyconfig: { ...parsedProperties },
                            },
                            on_conflict: {
                              constraint: "assetconfiguration_pkey",
                              update_columns: ["propertyconfig"],
                            },
                          },
                        },
                        master_assetstructure_array: {
                          data: {
                            // If the asset is in another folder, update the folder to the new one
                            structureid: props.structureId,
                          },
                          on_conflict: {
                            constraint: "assetstructure_pkey",
                            update_columns: ["structureid"],
                          },
                        },
                      });

                      insertion.assetDomains = insertion.assetDomains.concat(
                        asset.tags
                          .split(",")
                          .filter((domainName: string) => domainName !== "")
                          .map((domainName: string) => {
                            let domain = newDomainsList.find(
                              (domain) => domain.value.name === domainName
                            );
                            if (!domain) {
                              throw new Error("missing domain ID");
                            }
                            return { assetid, codelistitemid: domain.id };
                          })
                      );
                      return insertion;
                    },
                    { objects: [], assetIds: [], assetDomains: [] }
                  );
                  const on_conflict = {
                    constraint: "asset_pkey",
                    update_columns: ["assetname", "modelid"],
                    where: {
                      _not: {
                        master_assetconfiguration_array: {
                          propertyconfig: {
                            _contains: { isEditable: false },
                          },
                        },
                      },
                    },
                  };
                  upsertAssetsMutation({
                    variables: {
                      objects,
                      on_conflict,
                      assetIds,
                      assetDomains,
                    },
                  })
                    .then(() => {
                      if (assetsInAnotherFolder.length) {
                        publishWarning(
                          t(
                            "app:screen.structure.panel.importStructureCSV.assetUpdatedInAnotherFolder",
                            {
                              assetsCount: assetsInAnotherFolder.length,
                            }
                          )
                        );
                      }
                      publishSuccess(
                        t(
                          "app:screen.structure.panel.importStructureCSV.success",
                          {
                            structureCount: importElements.length,
                          }
                        )
                      );
                    })
                    .catch((e) => {
                      NotifyError(e);
                    });
                })
                .catch((e) => {
                  NotifyError(e);
                });

              closePanel();
              return;
            }
            /**
             * ------ Asset-only import end ------
             */

            /**
             * ------ Asset+structure import start ------
             */
            const structures = importElements.filter(isStructure);
            const assets = importElements.filter(isAsset);

            // Get the new domains that are not in the database
            const newDomains = assets.flatMap((asset) =>
              asset.tags
                .split(",")
                .filter(
                  (domainName) =>
                    !domainsList.find(
                      (domain: any) => domain.value.name === domainName
                    ) && domainName !== ""
                )
            );

            // Create the new folders in the database.
            // It creates all the folders, even if they already exist. On conflict, does nothing.
            insertStructureMutation({
              variables: {
                objects: structures.map((structure) => {
                  return {
                    structureid: structure.structureid,
                    parentid: structure.parentid,
                    properties: {
                      name: structure.name,
                      type: structure.type,
                      factoryStructure: true,
                    },
                  };
                }),
                // If the structure already exists, do nothing
                on_conflict: {
                  constraint: "structure_pkey",
                  update_columns: [],
                },
              },
            })
              .then(() =>
                createNewDomains({
                  variables: {
                    objects: newDomains.map((domain) => ({
                      value: { name: domain },
                      code_list_id: codeListId,
                    })),
                  },
                }).then((newDomainsResponse) => {
                  const newDomainsList = [
                    ...domainsList,
                    ...newDomainsResponse.data["insert_master_code_list_item"]
                      .returning,
                  ];
                  const { objects, assetIds, assetDomains } = assets.reduce(
                    (
                      insertion: {
                        objects: any;
                        assetIds: string[];
                        assetDomains: any;
                      },
                      asset: Asset
                    ) => {
                      insertion.assetIds.push(asset.assetid);
                      let {
                        assetname,
                        assetid,
                        modelid,
                        tags,
                        structureid,
                        ...properties
                      } = asset;
                      let parsedProperties: any = properties;
                      parsedProperties = getModelProperties(
                        modelid as AssetModel,
                        properties
                      );
                      insertion.objects.push({
                        ...{
                          assetid: assetid,
                          assetname: assetname,
                          modelid: modelid || values.type,
                          master_assetconfiguration_array: {
                            data: {
                              activeat: new Date().toISOString(),
                              propertyconfig: { ...parsedProperties },
                            },
                            on_conflict: {
                              constraint: "assetconfiguration_pkey",
                              update_columns: ["propertyconfig"],
                            },
                          },
                        },
                        master_assetstructure_array: {
                          data: {
                            structureid: structureid || undefined,
                          },
                          // If the asset is in another folder, update the folder to the new one
                          on_conflict: {
                            constraint: "assetstructure_pkey",
                            update_columns: ["structureid"],
                          },
                        },
                      });
                      insertion.assetDomains = insertion.assetDomains.concat(
                        asset.tags
                          .split(",")
                          .filter((domainName: string) => domainName !== "")
                          .map((domainName: string) => {
                            let domain = newDomainsList.find(
                              (domain) => domain.value.name === domainName
                            );
                            if (!domain) {
                              throw new Error(t("missingDomain"));
                            }
                            return { assetid, codelistitemid: domain.id };
                          })
                      );
                      return insertion;
                    },
                    { objects: [], assetIds: [], assetDomains: [] }
                  );
                  const on_conflict = {
                    constraint: "asset_pkey",
                    update_columns: ["assetname", "modelid"],
                    where: {
                      _not: {
                        master_assetconfiguration_array: {
                          propertyconfig: {
                            _contains: { isEditable: false },
                          },
                        },
                      },
                    },
                  };

                  upsertAssetsMutation({
                    variables: {
                      objects,
                      on_conflict,
                      assetIds,
                      assetDomains,
                    },
                  })
                    .then(() => {
                      publishSuccess(
                        t(
                          "app:screen.structure.panel.importStructureCSV.success",
                          {
                            structureCount: importElements.length,
                          }
                        )
                      );
                    })
                    .catch((e) => {
                      NotifyError(e);
                    });
                })
              )
              .catch((e) => {
                NotifyError(e);
              });
            closePanel();
            /**
             * ------ Asset+structure import end ------
             */

            /**
             * After importing the structure, the selectedStructureChildrenIds should be updated
             * to reflect the new structure in case the selectedStructure has new imported children.
             * This will refresh the Assets datagrid to display assets from the new structure children.
             */
            if (selectedStructure) {
              setSelectedStructureChildrenIds(
                getStructureChildrenIds([selectedStructure])
              );
            }
          })
          .catch((error: Error) => {
            setLoading(false);
            publishAlert(
              t(
                "app:screen.structure.panel.importStructureCSV.incorrectFileFormat"
              )
            );
            setFieldError(
              "file",
              `File validation error:${error.name} - ${error.message}`
            );
          });
      },
    });
  }, [
    values.type,
    setFieldError,
    t,
    publishInfo,
    createNewDomains,
    closePanel,
    allAssetList,
    props.structureId,
    domainsList,
    codeListId,
    upsertAssetsMutation,
    publishSuccess,
    publishWarning,
    insertStructureMutation,
    selectedStructure,
    setSelectedStructureChildrenIds,
    publishAlert,
  ]);

  const handleValidate = (value: keyof StructureForm): string => {
    return formikErrors[value] && touched[value]
      ? (formikErrors[value] as string)
      : "";
  };

  let propertyList = fullAssetPropertyList;
  if (values.type === StructureImportType.All) {
    propertyList = [...structurePropertyList, ...propertyList];
  }

  useKeyPress("Enter", () => handleImport());

  return (
    <div className={styles.slideContainer}>
      <SlidePanel
        isOpen={isOpen}
        closePanel={closePanel}
        title={t("importCSV")}
        bottomActions={
          <Button
            disabled={
              !fileLoaded ||
              !(
                inputRef?.current?.files?.length === 1 &&
                inputRef.current.files[0].type === "text/csv"
              )
            }
            text={t(`app:screen.structure.panel.importStructureCSV.import`)}
            onPress={handleImport}
            type="primary-blue"
          />
        }
      >
        <form className={styles.form}>
          <Dropdown
            label={t(
              "app:screen.structure.panel.importStructureCSV.selectType"
            )}
            value={
              values.type
                ? [
                    {
                      value: values.type,
                      label: t(
                        `app:screen.structure.panel.importStructureCSV.${values.type}`
                      ),
                    },
                  ]
                : []
            }
            onChange={(v) => {
              setFieldValue("type", v[0]?.value);
              setTimeout(() => setFieldTouched("type"), 500);
            }}
            showValidationBarWhenInvalid={true}
            showValidationIconWhenInvalid={true}
            validationState={{
              valid: handleValidate("type") === "",
              message: handleValidate("type"),
            }}
            searchable={true}
            sizeClass="large"
            required
            disabled={props.onlyAllowImportAssets}
          >
            <DropdownOption
              label={t("app:screen.structure.panel.importStructureCSV.assets")}
              value={StructureImportType.AssetsOnly}
            />
            <DropdownOption
              label={t("app:screen.structure.panel.importStructureCSV.all")}
              value={StructureImportType.All}
            />
          </Dropdown>
          {formikErrors.file ? (
            <ErrorBox messages={[formikErrors.file!] as string[]} />
          ) : (
            []
          )}
          <div className={styles.bottomForm}>
            <input
              className="mt-2 mb-2"
              id="containerId"
              ref={inputRef}
              type="file"
              accept="text/csv"
              onClick={() => setLoading(true)}
              onChange={() => {
                setLoading(false);
                handleInputChange();
              }}
            />

            <div className={styles.informationSection}>
              <InfoButton
                placement="top-start"
                colors={{
                  primaryColor: ABBColors.Red60,
                  hoveredColor: ABBColors.Red70,
                }}
                className={styles.infoIcon}
              >
                <>
                  <span className="pt-4">
                    {t("app:screen.structure.panel.importStructureCSV.fields")}
                  </span>
                  {propertyList?.map((val: string, idx: number) => (
                    <li key={`property-val-${idx}`}>{val}</li>
                  ))}
                </>
              </InfoButton>
              <p className={classNames("pt-4", styles.label)}>
                {t("app:screen.structure.panel.importStructureCSV.checkFields")}
              </p>
            </div>
            <a
              className={`mt-2 ${styles.downloadSample}`}
              href={getFileRef(values.type)}
            >
              <Icon name="download" style={{ color: "inherit" }} />
              {t(`downloadSampleCSV`)}
            </a>
          </div>
        </form>
        <LoadingIndicator
          className={cx({ hidden: !loading })}
          determinate={false}
          color="blue"
          type="bar"
        />
      </SlidePanel>
    </div>
  );
};

const allSchema = yup.array().of(
  yup.object().shape({
    structureid: yup.string(),
    parentid: yup.string(),
    name: yup.string(),
    type: yup.string().when("parentid", {
      is: (val: string) => !!val,
      then: (schema) =>
        schema
          .oneOf(["subgroup", "module"], i18n.t("yup:errors.moduleOrSubgroup"))
          .required(),
      otherwise: (schema) => schema,
    }),
    modelid: yup.string(),
    assetid: yup.string(),
    assetname: yup.string(),
    hostname: yup.string(),
    user: yup.string(),
    password: yup.string(),
    robapiport: yup.string(),
    systemid: yup.string(),
    rwversion: yup.string(),
    mode: yup.string(),
    pathname: yup.string(),
    opcuaMessageSecurityMode: yup.string(),
    opcuaSecurityPolicy: yup.string(),
    certificate: yup.string(),
    privateKey: yup.string(),
    certificateFilename: yup.string(),
    privateKeyFilename: yup.string(),
    sourceTopics: yup.string().when(["modelid", "destinationTopics"], {
      is: (modelid: AssetModel, val: string[]) =>
        modelid === AssetModel.MQTT && (!val || val.length === 0),
      then: (schema: any) =>
        schema.required("At least a topic has to exist on MQTT devices"),
    }),
    destinationTopics: yup.string(),
    port: yup.string(),
    collection: yup.string(),
    protocol: yup.string(),
    database: yup.string(),
    persistinterval: yup.string(),
    connectionString: yup.string(),
    tags: yup.string(),
  })
);

const assetSchema = yup.array().of(
  yup.object().shape({
    modelid: yup.string(),
    assetid: yup.string(),
    assetname: yup.string(),
    hostname: yup.string(),
    user: yup.string(),
    password: yup.string(),
    robapiport: yup.string(),
    systemid: yup.string(),
    rwversion: yup.string(),
    mode: yup.string(),
    pathname: yup.string(),
    opcuaMessageSecurityMode: yup.string(),
    opcuaSecurityPolicy: yup.string(),
    certificate: yup.string(),
    privateKey: yup.string(),
    certificateFilename: yup.string(),
    privateKeyFilename: yup.string(),
    sourceTopics: yup.string().when(["modelid", "destinationTopics"], {
      is: (modelid: AssetModel, val: string[]) =>
        modelid === AssetModel.MQTT && (!val || val.length === 0),
      then: (schema: any) =>
        schema.required("At least a topic has to exist on MQTT devices"),
    }),
    destinationTopics: yup.string(),
    port: yup.string(),
    collection: yup.string(),
    protocol: yup.string(),
    database: yup.string(),
    persistinterval: yup.string(),
    connectionString: yup.string(),
  })
);

function getValidationSchema(type: StructureImportType) {
  switch (type) {
    case StructureImportType.All:
      return allSchema;
    case StructureImportType.AssetsOnly:
      return assetSchema;
  }
}
function getFileRef(type: StructureImportType) {
  switch (type) {
    case StructureImportType.AssetsOnly:
      return sample_all_assets;
    case StructureImportType.All:
      return sample_all_structure;
  }
}

function isStructure(element: Asset | Structure): element is Structure {
  return (
    (element as Structure).name !== undefined &&
    (element as Structure).name !== ""
  );
}
function isAsset(element: Asset | Structure): element is Asset {
  return (
    (element as Asset).modelid !== undefined &&
    (element as Asset).modelid !== ""
  );
}

const robotRCProperties: string[] = ["hostname", "user", "password", "mode"];

const robotRVProperties: string[] = [
  "hostname",
  "user",
  "password",
  "robapiport",
  "systemid",
  "rwversion",
  "mode",
];

const opcuaProperties: string[] = [
  "protocol",
  "hostname",
  "port",
  "user",
  "password",
  "pathname",
  "mode",
  "opcuaMessageSecurityMode",
  "opcuaSecurityPolicy",
  "certificate",
  "privateKey",
  "certificateFilename",
  "privateKeyFilename",
];

const mqttProperties: string[] = [
  "protocol",
  "hostname",
  "port",
  "user",
  "password",
  "sourceTopics",
  "destinationTopics",
  "mode",
];

const mongoProperties: string[] = [
  "protocol",
  "hostname",
  "port",
  "user",
  "password",
  "database",
  "collection",
  "mode",
];

const postgreSqlProperties: string[] = [
  "protocol",
  "hostname",
  "port",
  "user",
  "password",
  "database",
  "table",
  "persistinterval",
  "mode",
];

const ipsProperties: string[] = [
  "hostname",
  "mode",
];

function getModelProperties(modelid: AssetModel, properties: any) {
  switch (modelid) {
    case AssetModel.RobotRC:
      return pick(properties, robotRCProperties);
    case AssetModel.RobotRV:
      return pick(properties, robotRVProperties);
    case AssetModel.OpcUA:
      return pick(properties, opcuaProperties);
    case AssetModel.MQTT:
      const modelProps: any = pick(properties, mqttProperties);
      return {
        ...modelProps,
        sourceTopics: modelProps.sourceTopics.split(",").filter(Boolean),
        destinationTopics: modelProps.destinationTopics
          .split(",")
          .filter(Boolean),
      };
    case AssetModel.MongoDB:
      return pick(properties, mongoProperties);
    case AssetModel.PostgreSQL:
      return pick(properties, postgreSqlProperties);
    case AssetModel.IPS:
      return pick(properties, ipsProperties);
  }
}
