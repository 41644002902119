import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Datagrid,
  Pagination,
  ThreeStateValue,
} from "@abb/abb-common-ux-react";

import { DataGridSortDetails } from "types/DataGridSortDetails";
import { User } from "types/identity/User";
import { useLoggedUserPermission } from "libs/hooks/useLoggedUserPermission";
import { UserFilter } from "./panels/Filters";
import objectsAreEqual from "utils/objectsAreEqual";
import styles from "./Users.module.scss";
import usePaginationTranslation from "libs/hooks/usePagination";
interface UsersDataGridProps {
  data: [User] | any;
  handleOnUserSelected: Function;
  handleSelectAll: () => void;
  selectAllState: ThreeStateValue;
  filters?: UserFilter;
}

export interface UsersDataGridRef {
  refetch: () => void;
}

export const UsersDataGrid = ({
  data,
  handleOnUserSelected,
  handleSelectAll,
  filters,
}: UsersDataGridProps) => {
  const { hasEditUserPermission } = useLoggedUserPermission();
  const { t } = useTranslation();

  const [sortDetails, setSortDetails] = useState<DataGridSortDetails | null>({
    field: "id",
    desc: true,
  });
  // HOOK
  const paginationTranslation = usePaginationTranslation();
  const pageSizes = [10, 30, 50];
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [page, setPage] = useState(0);
  const [prevFilters, setPrevFilters] = useState(filters);
  const params = useParams<{ section?: string; idElement: string }>();

  useEffect(() => {
    if (params.section === "org") {
      setPage(0);
    }
  }, [params.section, params.idElement]);

  useEffect(() => {
    if (filters) {
      if (objectsAreEqual(filters, prevFilters)) {
        return;
      }
      setPrevFilters(filters);
      setPage(0);
    }
  }, [filters, prevFilters]);

  return (
    <>
      <div className={styles.dataGridContainer}>
        <Datagrid
          className="pb-4"
          data={data}
          columns={[
            { fieldKey: "name", title: t("name") },
            { fieldKey: "groups", title: t("groups") },
            { fieldKey: "email", title: t("email") },
            { fieldKey: "phone", title: t("phone") },
            { fieldKey: "accessLevel", title: t("accessLevel") },
            {
              fieldKey: "actions",
              sortable: false,
              width: 40,
              maxWidth: 40,
            },
          ]}
          pageSize={pageSize}
          page={page}
          sortOrder={sortDetails || undefined}
          onSort={(sort: any) =>
            sort
              ? setSortDetails({ field: sort.field, desc: sort.desc })
              : setSortDetails(null)
          }
          onToggleRowSelect={(rowIndex, oldValue) =>
            handleOnUserSelected(rowIndex, oldValue)
          }
          zebraColoring
          enableColumnDragging={false}
          enableSorting
          enableRowSelection={hasEditUserPermission}
          borderStyle="discreet"
          onToggleSelectAll={handleSelectAll}
        />
      </div>
      <Pagination
        showPageSelectionInput={true}
        onGotoPage={(newPage) => setPage(newPage)}
        onSetPageSize={setPageSize}
        currentPage={page}
        currentPageSize={pageSize}
        rowCount={data.length}
        showPrevNext={true}
        showNumberButtons={false}
        showInput={true}
        pageSizeOptions={pageSizes}
        texts={paginationTranslation}
      />
    </>
  );
};
