export enum ACCESS_LEVELS {
  DEFAULT = 1,
  ADMIN = 2,
  VIEWER = 3,
}

export interface AccessLevel {
  id: number;
  name: string;
  groupId: string;
}
