import { Group } from "types/identity/Group";
import { Role } from "types/identity/Role";

export const filterInternalGroups = (elements?: Group[]) =>
  elements?.filter(
    (element: Group) =>
      !element.name.endsWith("-internal") || element.isInternal
  );
export const filterInternalRoles = (elements?: Role[]) =>
  elements?.filter((element: Role) => !element.name.endsWith("-internal"));
