import { useMutation, useQuery } from "@apollo/client";
import { deleteAssets } from "gql/storageApi/mutations/assetMutations";
import {
  addStructureChild,
  changeParentId,
  changeProperties,
  deleteAssetStructure,
} from "gql/storageApi/mutations/structureMutations";
import { getStructures } from "gql/storageApi/queries/structureQueries";
import { useMemo } from "react";
import { IStructureTreeItem } from "./StructureTreeItem";
import { buildTree } from "./utils";
import { ApolloContexts } from "services/ApolloService";

export const useElementUpperRightPosition = (
  element: HTMLElement | null
): { x: number; y: number } => {
  const domRect = element?.getBoundingClientRect();
  if (domRect && element) {
    const { x, y } = domRect;
    return {
      x: x + element.offsetWidth,
      y,
    };
  }
  return { x: 0, y: 0 };
};

export const useStructureTree = () => {
  const { data, loading, refetch } = useQuery(getStructures, {
    fetchPolicy: "cache-and-network",
    context: ApolloContexts.Hasura.context,
  });
  const structure = data?.["master_structure"];
  return {
    tree: useMemo(() => buildTree(structure), [structure]),
    loading,
    refetch,
  };
};

export const useAddLevelMutation = () =>
  useMutation(addStructureChild, {
    refetchQueries: ["getStructures"],
    context: ApolloContexts.Hasura.context,
  });

export const useRemoveLevelMutation = () => {
  // Get mutations
  const [removeStructure] = useMutation(
    deleteAssetStructure,
    {
      refetchQueries: ["getStructures"],
      context: ApolloContexts.Hasura.context,
    }
  );
  const [deleteAssetMutation] = useMutation(
    deleteAssets,
    {
      refetchQueries: ["getStructures"],
      context: ApolloContexts.AssetsApi.context,
    }
  );
  /**
   * Removes a structure level including all inferior level and assets
   * @param item structure level to remove.
   */
  const removeLevel = async (item: IStructureTreeItem): Promise<void> => {
    if (!item) {
      return;
    }
    // Remove inner levels before removing current level
    if (item.children?.length) {
      await Promise.all(item?.children?.map(removeLevel));
    }
    // Remove current level
    const { data } = await removeStructure({ variables: { id: item.id } });
    // Remove level assets
    const assetIds = data.delete_master_assetstructure.returning.map(
      (r: any) => r.assetid
    );
    if (assetIds?.length) {
      await deleteAssetMutation({ variables: { assetIds } });
    }
    return;
  };
  return { removeLevel };
};

export const useRenameMutation = () =>
  useMutation(changeProperties, {
    refetchQueries: ["getStructures"],
    context: ApolloContexts.Hasura.context,
  });

export const useChangeParentMutation = () =>
  useMutation(changeParentId, {
    refetchQueries: ["getStructures"],
    context: ApolloContexts.Hasura.context,
  });
