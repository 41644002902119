import { Icon, Input, Row } from "@abb/common-ux";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { TFunction, useTranslation } from "react-i18next";
import styles from "./RetentionPolicyDialog.module.scss";
import { duration } from "moment";
import classNames from "classnames";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
interface Props {
  formerPolicy?: string;
  selectedPolicy: string;
  setSelectedPolicy: (policy: string) => void;
  onSubmit: () => void;
  onAbort: () => void;
}

type TimeUnit = "weeks" | "months";

const defineMessage = (
  value: number,
  newTimeUnit: TimeUnit,
  setMessage: Dispatch<SetStateAction<string>>,
  t: TFunction<"translation", undefined>,
  formerPolicy?: string
) => {
  if(!value) {
    setMessage(t("app:screen.structure.dialog.policyEmptyWarning"));
    return;
  }
  const newPolicyDuration = duration(value, newTimeUnit);
  const formerPolicyValues = formerPolicy?.split(" ");
  const EXPECTED_POLICY_VALUES_LENGTH = 2;
  if (formerPolicyValues?.length !== EXPECTED_POLICY_VALUES_LENGTH){
    setMessage("");
    return;
  }
  const formerPolicyDuration = duration(parseInt(formerPolicyValues[0]), formerPolicyValues[1] as TimeUnit)
  if (
    newPolicyDuration.asMilliseconds() <
      (formerPolicyDuration?.asMilliseconds() ?? 0)
  ) {
    setMessage(t("app:screen.structure.dialog.lowerPolicyChangeWarning"));
    return;
  }
  if (
    newPolicyDuration.asMilliseconds() >
      (formerPolicyDuration?.asMilliseconds() ?? 0)
  ) {
    setMessage(t("app:screen.structure.dialog.higherPolicyChangeWarning"));
    return;
  }
  setMessage("");
};

const RetentionPolicyDialog = ({
  setSelectedPolicy,
  formerPolicy,
  onSubmit,
  onAbort,
}: Props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const timeUnits = ["weeks", "months"];
  const timeUnitLabels = [
    t("app:screen.structure.dialog.weeks"),
    t("app:screen.structure.dialog.months"),
  ];
  const MIN_WRITABLE_POLICY_VALUE = 0;
  const MIN_VALID_POLICY_VALUE = 1;
  const MAX_VALID_POLICY_VALUE = 12;
  let [prevValue, prevUnit] =
    formerPolicy
      ?.split(" ")
      .map((val, index) => (index === 0 ? parseInt(val) : val)) || [];
  if ((prevValue as number) > MAX_VALID_POLICY_VALUE) {
    prevValue = 1;
  }
  if(!timeUnits.includes(prevUnit as string)) {
    prevUnit = "weeks";
  }
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<TimeUnit>(
    (prevUnit as TimeUnit) ?? "weeks"
  );
  const [value, setValue] = useState<number>(
    isNaN(prevValue as number) ? 1 : prevValue as number
  );

  useEffect(() => {
    if (formerPolicy) {
      setSelectedPolicy(formerPolicy);
    }
  }, [formerPolicy, setSelectedPolicy]);

  const handlePolicyChange = useCallback(
    (timeUnitSelection: { value: TimeUnit; label: string }[]) => {
      const newTimeUnit: TimeUnit = timeUnitSelection[0].value;
      setSelectedTimeUnit(newTimeUnit);
      setSelectedPolicy(`${value || 1} ${newTimeUnit}`);
      defineMessage(value, newTimeUnit, setMessage, t, formerPolicy);
    },
    [formerPolicy, setSelectedPolicy, t, value]
  );

  const handleValueChange = useCallback(
    (value) => {
      if (value < MIN_WRITABLE_POLICY_VALUE || value > MAX_VALID_POLICY_VALUE) {
        return;
      }
      setValue(value);
      const newPolicy = `${value} ${selectedTimeUnit}`;
      setSelectedPolicy(newPolicy);
    },
    [setSelectedPolicy, selectedTimeUnit]
  );

  const onLostFocus = useCallback(() => {
    defineMessage(value, selectedTimeUnit, setMessage, t, formerPolicy);
  }, [formerPolicy, t, selectedTimeUnit, value]);

  return (
    <form
      className={styles.form}
      id="retention-policy-form"
      onSubmit={onSubmit}
      onAbort={onAbort}
      onMouseLeave={onLostFocus}
    >
      <Row>
        <Input
          label={t("retentionPolicy")}
          type="number"
          value={value}
          onInput={(e) =>
            handleValueChange((e.target as HTMLInputElement).value)
          }
          onBlur={onLostFocus}
          className={styles.input}
          form="retention-policy-form"
          required
          min={MIN_VALID_POLICY_VALUE}
          max={MAX_VALID_POLICY_VALUE}
        />
        <Dropdown
          className={styles.dropdown}
          label=" "
          sizeClass="large"
          value={[
            {
              value: selectedTimeUnit,
              label:
                timeUnitLabels[timeUnits.indexOf(selectedTimeUnit || "weeks")],
            },
          ]}
          onChange={handlePolicyChange}
        >
          {timeUnits.map((timeUnit, index) => (
            <DropdownOption
              key={timeUnit}
              value={timeUnit}
              label={timeUnitLabels[index]}
            />
          ))}
        </Dropdown>
      </Row>
      <div
        className={classNames(styles.message, { [styles.invisible]: !message })}
      >
        <Icon
          style={{ color: "inherit", marginTop: 2 }}
          size={16}
          name={"warning-circle-2"}
        />
        {message}
      </div>
      <input type="submit" hidden />
    </form>
  );
};

export default RetentionPolicyDialog;
